.refunds-service-sec-1 {
  padding: 6%;
}
.refunds-service-sec-1 h1 {
  text-align: center;
  color: black;
  font-weight: inherit;
  font-size: 60px;
}
.refunds-service-sec-1 h2 {
  color: #40606c;
  text-align: center;
  font-weight: inherit;
  font-size: 35px;
  padding-bottom: 1%;
}
.refunds-service-sec-1 p {
  padding-top: 1%;
  text-align: center;
  color: black;
  font-size: 20px;
  font-weight: inherit;
}
.refunds-service-sec-1 .refundbtn {
  margin: 0 auto;
  display: table;
}
.refunds-service-sec-2 {
  padding: 6%;
  background-color: #f3f5f6;
}
.refunds-service-sec-2 h1 {
  text-align: center;
  color: black;
  font-weight: 600;
  font-size: 60px;
}
.refunds-service-sec-2 p {
  text-align: center;
  color: black;
  font-weight: inherit;
  font-size: 20px;
  padding-top: 2%;
}
.refunds-service-sec-3 {
  padding: 1% 0;
  background-color: black;
}
.refunds-service-sec-3 p {
  color: white;
  font-size: 18px;
}
button.blue-btn.dynamic-detailed-btn.btn.btn-outline-light.my-5 {
  float: left;
}
p.services-detailed-desc {
  font-size: 20px !important;
  text-align: center !important;
  width: 70%;
  margin: 0 auto;
}
section.refunds-service-sec-3 h1 {
  font-size: 40px;
  padding-bottom: 2%;
}
section.refunds-service-sec-3 {
  color: white;
}
.refunds-h1 {
  font-size: 45px !important;
  color: white;
}
.refunds-p {
  text-align: center;
  color: white;
  font-size: 25px;
}
.partnership-second-sec .partner-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.partnership-second-sec {
  background-color: black;
  text-align: center;
  padding-top: 3%;
  padding-bottom: 3%;
}

.partnership-second-sec h1 {
  color: white;
  font-size: 40px;
}
.partnership-second-sec p {
  font-size: 20px;
  color: #cdcdcd;
}

h2.inner-service-head {
  padding-top: 5%;
  text-align: center;
  color: white;
  font-size: 50px;
}
section.refunds-service-sec-3 button {
  background: white;
  border-radius: 0;
  border: 2px solid #ab0f53;
  color: black;
  font-weight: 500;
}

section.refunds-service-sec-3 button:hover {
  background: #ab0f53;
  border: 2px solid white;
  color: white;
}
section.refunds-service-sec-3 button {
  display: table;
  margin: 0 auto;
  padding: 10px 50px;
  text-decoration: none !important;
  font-size: 20px;
}
a.nounderline {
  text-decoration: none;
}
h1.inner-service-subhead {
  text-align: center;
  color: #cdcdcd;
  font-size: 23px;
  font-weight: 400;
  width: 60%;
  margin: 0 auto;
}
