.example-images {
  cursor: pointer;
}

.card-image {
  border: 2px solid #ab0f53;
}

body.custom-modal {
  overflow: hidden;
}
.modal {
  background-color: rgba(0, 0, 0, 0.8) !important;
  min-height: 45vh;
  min-width: "500px";
}
.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: hidden;
}
.close-button {
  position: absolute;
  right: 10px;
  font-size: 30px;
  cursor: pointer;
  color: #fff;
  z-index: 1001;
}

.close:hover {
  color: #ab0f53;
}
