.resources-sec-1{
    padding: 6%;
}
.resources-sec-1 p{
    color: #cdcdcd;
    font-size: 20px;
    padding-bottom: 3%;
}
.resources-sec-1 h1{
    text-align: center;
    color: black;
    font-weight: inherit;
    font-size: 60px;
}
.resources-sec-1{
    background-color: black;
    text-align: center;
    color: #000;
    font-size: 20px;
}
.resources-sec-1 .card {
    padding: 20px;
    padding-top: 80px;
    background-image: url('../../assets/home-services-frame-graphic.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 350px;
    height: 350px;
    margin: 0 auto;

    border: 0;
}
.resources-sec-1 button:hover{
    background-color: #D41367;
    border: none;
}
.resources-sec-1 button {
    background: white;
    border: 2px solid #ab0f53;
    color: black;
    font-weight: 500;
    border-radius: 0;
    font-size: 20px;
    padding: 5px 25px;
    margin-top: 5%;
}
.resources-second-sec{
    padding: 6%;
    background-color: #f3f5f6;
}
.resources-second-sec h1{
    text-align: center;
    color: #000;
    font-size: 50px;
}
.resources-second-sec .card-body{
    background-color: black;
    text-align: center;
}

.pink-card.card-header {
    background: #d40f67;
    color: white;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
}

.light-card.card-header {
    text-align: center;
    background: #aac6c9;
    font-weight: 600;
    font-size: 25px;
}
.featured-resource-sec{
    background-color: black;
    padding-top: 3%;
    padding-bottom: 3%;
}
.featured-resource-sec h1, .featured-resource-sec .title {
    color: white;
    font-size: 40px;
    padding-top: 7%;
}
.featured-resource-sec p {
    font-size: 20px;
    color: white;
}
section.featured-resource-sec button {
    background: #d41367;
    border: 2px solid white;
    border-radius: 0;
    font-size: 20px;
    padding: 5px 25px;
}

section.featured-resource-sec button:hover {
    background: white;
    border: 2px solid #d41367;
    color: #d41367;
}
