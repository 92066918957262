
.contactintrosec{
    background-image: url('../../assets/home-hero-bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    display: flex;
    min-height: 750px;
    margin-top: -50px;
}
.contactintrosec {
    min-height: 375px;
}
p.contact-intro-subtext {
    text-align: center;
    color: white;
    width: 60%;
    margin: 0 auto;
    font-size: 23px;
    margin-bottom: 2rem;
}
.contactintrosec:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100px;
    bottom: 0px;
    left: 0px;
    background: linear-gradient(to top,black,rgba(0 0 0 / 0%));
}
.contactintrosec:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: linear-gradient(to bottom,black,rgba(0 0 0 / 0%));
}

.contact-intro-title{
    text-align: center;
    color: white;
    font-size: 40px;
    padding-top: 6.5%;
    padding-bottom: 2.5%;
}
    .second-sec-contact{
        background-color: black;
        padding: 3%;
    }
    .second-sec-contact h2 {
         color:  white;
         font-size: 40px;
         text-align: left;
    }
    .second-sec-contact p {
        color: white;
        font-size: 17px;
        text-align: left !important;
    }

    .contact-first-col button {
        background: #d41367;
        border-radius: 0;
        border: 1px solid white;
        font-size: 20px;
    }
    .contact-first-col button {
        background: #d41367;
        border-radius: 0;
        border: 1px solid white;
        font-size: 20px;
    }

    .contact-second-col.col label {
        color: white;
        font-weight: 400;
        font-size: 18px;
        padding-top: 5%;
    }

    .contact-second-col.col input {
        background: black;
        border-radius: 3px;
    }

    .contact-second-col.col textarea.form-control {
        background: black;
        margin-bottom: 5%;
        border-radius: 2px;
    }


.contact-second-col.col button {
    background: white;
    border: 2px solid #d41367;
    border-radius: 0px;
    color: black;
    font-size: 20px;
    font-weight: 500;
    padding: 5px 25px;
}

.contact-second-col.col button:hover {
    background: #d41367;
    color: white;
    border: 2px solid white;
}
