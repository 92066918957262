a.nav-link-dash {
    padding: 50px;
    color: black;
    font-size: 25px;
}
i.fas.fa-sign-out-alt {
    font-size: 40px;
    color: white;
    padding: 0 20px;
}
.nav-sidebar>.nav-item>.nav-link:active {
    color: #c2c7d0;
    background: #f0f8ff26;
}
.table-backend-img-author{
    width: 50px;
    height: 50px;
    border-radius: 200px;
}
.login-page{
    background-image: url('../assets/home-hero-bg.webp');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.card-primary.card-outline {
    border-top: 3px solid #b42d68;
    border: 3px solid #b42d68 ;
}
.adminform-background{
    background-color: white;
}
.table-backend-img{
    width: 100px;
}
.aside.main-sidebar {
    background-image: url('../assets/pink-pattern.png') !important;
    background-position: 50%;
    background-size: 200px;
  }
  nav .nav li.nav-item a {
    color: white !important;
    text-decoration: none;
}

nav .nav li.nav-item a:hover {
    background: white;
    color: #db3780 !important;
}
.info p {
    font-size: 30px;
    font-weight: 500;
}
.brand-link img {
    width: 100%;
    background-color: white;
}
a.brand-link {
    padding: 0;
}

.img-box-dashboard-featured img {
    width: 100%;
    padding-right: 5%;
    padding-bottom: 5%;
}


.login-box button {
    border: 2px solid  #dd1268;
    background: white;
    color: black;
    font-weight: 500;
    border-radius: 0;
}

.login-box {
    background: black;
}

.login-box .card-header {
    background: black;
    color: white;
}

.login-box .card-body {
    background: black;
    color: white;
}

.card-body input {
    background: black;
    color: white;
}


/* Style for description cell */
.description-cell {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .position-cell {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .teamname-cell {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .teamnameserial-cell {
    max-width: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    padding: 2% !important;
  }

  /* Style for social icon cell */
  .social-icon-cell {
    width: 50px;
    text-align: center;
  }

  /* Style for action cell */
  .action-cell {
    width: 120px;
    text-align: center;
  }

  /* Style for action icons */
  .action-icons > * {
    margin-right: 5px;
    cursor: pointer;
  }

  /* Style for avatar image */
  .table-backend-imgs {
    max-width: 50px;
    max-height: 50px;
    object-fit: cover;
    border-radius: 50%;
  }
