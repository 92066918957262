.footer{
    background-image:url('../../assets/footer-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
}
.footer:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: linear-gradient(to bottom,black,black 60%,rgba(0 0 0 / 0%));
}

ul a{
    text-decoration: none !important;
}
ul li a {
    text-decoration: none !important;
    color:white !important;
}
.card-header {
    border: none;
}
.privacy-policytext{
    font-size: 19px;
}
.footer-card{
    box-shadow: none;
}
section.footer p {
    color: white;
    font-size: 17px;
}

section.footer h2 {
    color: white;
    text-transform: uppercase;
    font-size: 25px;
    padding-bottom: 2%;
}

section.footer ul li {
    list-style: none;
    color: white;
    font-size: 17px;
}

section.footer ul {
    padding-left: 0;
}