.services-first-sec{
    padding: 6%;
}

.services-first-sec h1 {
    font-size: 50px;
    font-weight: inherit;
}

.services-first-sec h2 {
    color: #00000085;
    font-weight: inherit;
    font-size: 30px;
}

.services-first-sec p {
    font-size: 22px;
    color: black;
}
.service-second-sec{
    padding: 3% 28%;
    background-color: black;

    @media (max-width: 900px) {
        padding: 0 1% !important;
    }
}


span.serv {
    font-size: 25px !important;
    font-weight: inherit !important;
}
.services-third-sec{
    padding: 6%;
}

.accordion-flush .accordion-item {
    margin-bottom: 3%;
}
.accordion-button:not(.collapsed) {
    color: white !important;
    background: #d40f67 !important;
}
button.accordion-button.collapsed {
    background: #aac6c9;
}

button.accordion-button.collapsed:hover {
    background: #d40f67;
    color: white;
}

h1.services-top-head {
    text-align: center;
    color: white;
    padding-top: 5%;
    font-size: 50px;
}

h2.services-sub-head {
    text-align: center;
    font-size: 25px;
    color: #cdcdcd;
}
 .img-box-services{
    background-image: url('../../assets/home-services-frame-graphic.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 280px;
    height: 280px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
}
.img-box-services h2{
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    color: white;
    width: 80%;
    text-align: center;

}

.service-second-sec h1, .service-second-sec .title {
    color: white !important;
    padding-top: 5%;
    font-size: 45px;
}

.service-second-sec p {
    font-size: 18px;
    color: #cdcdcd;
}

.service-second-sec button {background: white;border: 2px solid #a51947;border-radius: 0;color: black;font-weight: 500;font-size: 20px;}

.service-second-sec button:active {
    background: #D41367 !important;
    border: none;

}

.service-second-sec button:hover {
    background: #D41367;
    color: white;
    border: 2px solid white;
}

@media  (min-width: 768px) and (max-width: 1366px) {
    .service-second-sec{
        padding: 3% 20%;
    }
}


@media  (min-width: 1366px) and (max-width: 1920px) {
    .service-second-sec{
        padding: 3% 20%;
    }
}
