h1.infographics-resource-heading {
  padding-top: 10%;
  color: white;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  h1.infographics-resource-heading {
    padding-top: 15%;
  }
}
