.styles-module--downloadContainer--b7b2b {
  background-color: #fff;
  background-color: var(--color-white);
  border-radius: 4px;
  border-radius: 5px;
  box-shadow: 1px 1px 7px rgb(0 42 58 / 20%);
  box-shadow: 0 0 50px 0 #00000033;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: box-shadow 0.3s, background-color 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s, transform 0.3s, background-color 0.3s;
  transition: box-shadow 0.3s, transform 0.3s, background-color 0.3s,
    -webkit-transform 0.3s;
  transition: var(--transition) box-shadow, var(--transition) transform,
    var(--transition) background-color;
  width: 100%;
}

a.styles-module--link--2993b {
  color: #486472;
  cursor: pointer;
  text-decoration: none;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.styles-module--download--d821d {
  width: 100%;
}

.styles-module--download--d821d
  > a
  .styles-module--downloadContainer--b7b2b
  > div:first-child {
  align-items: center;
  display: flex;
  justify-content: center;
}

.styles-module--download--d821d
  > a
  .styles-module--downloadContainer--b7b2b
  > div {
  flex-shrink: 0;
  width: 50%;
}
.styles-module--download--d821d
  > a
  .styles-module--downloadContainer--b7b2b
  > div:first-child
  h3 {
  font-size: 25px;
}
.styles-module--download--d821d
  > a
  .styles-module--downloadContainer--b7b2b
  .styles-module--buttonDownload--b6ed2 {
  align-items: center;
  background-color: #7f949c !important;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  color: white;
  font-size: 25px;
}

.styles-module--auto--693af {
  width: auto;
}

.styles-module--small--d5a40 {
  font-size: 14px;
  padding: 10px 20px;
}

.styles-module--container--668a0 {
  background-color: #2674b0;
  background-color: var(--color-blue);
  border-radius: 4px;
  border-radius: var(--border-radius);
  color: #fff;
  color: var(--color-white);
  font-family: Lato, sans-serif, system-ui, -apple-system, Segoe UI, Roboto,
    Ubuntu, Cantarell, Noto Sans;
  font-family: var(--font-family-heading);
  font-size: 16px;
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.8px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.3s;
  transition: var(--transition) background-color;
}

.flip-cards {
  background-color: transparent;
  width: 100%;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inners {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.news-letter-resource-second-sec {
  background-color: black;
}
.flip-card-front a {
  position: absolute;
  color: white;
  margin-top: 40%;
  font-size: 20px;
}

h3.newsletter-resource-box-title {
  font-size: 22px;
  width: 80%;
  padding-bottom: 5%;
  margin-top: -20%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: wrap;
}

/* Medium-sized screens */
@media (min-width: 390px) and (max-width: 1200px) {
  h3.newsletter-resource-box-title {
    font-size: 18px;
    width: 55%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 767px) and (max-width: 1200px) {
  h3.newsletter-resource-box-title {
    font-size: 18px;
    width: 80%;
    padding-left: 10px;
    padding-right: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: wrap;
  }
}

@media (max-width: 389px) {
  h3.newsletter-resource-box-title {
    font-size: 18px;
    width: 80%;
  }
}

section.news-letter-resource-second-sec button {
  margin-top: 5% !important;
  text-align: center;
  display: table;
  margin: 0 auto;
  background: white;
  color: black;
  font-size: 20px;
  font-weight: 500;
  border: 2px solid #ab0f53;
}

section.news-letter-resource-second-sec button:hover {
  background: #ab0f53;
  color: white;
  border: 2px solid white;
}
