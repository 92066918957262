.introsec {
  background-image: url("../../assets/home-hero-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  display: flex;
  min-height: 750px;
  margin-top: -100px;
}
.introsec {
  min-height: 575px;
}

.introsec:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100px;
  bottom: 0px;
  left: 0px;
  background: linear-gradient(to top, black, rgba(0 0 0 / 0%));
}
.introsec:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: linear-gradient(to bottom, black, rgba(0 0 0 / 0%));
  z-index: -1;
}
.introsec-firstrow {
  background-color: rgba(10, 5, 5, 0.8);
  text-align: center;
  margin: 0 auto;
  padding-top: 10%;
}

section.introsec h1 {
  color: white;
  font-size: 50px;
}

section.introsec p {
  color: white;
  font-size: 20px;
  padding: 1%;
}

.introsec button {
  background: white;
  border: 2px solid #ab0f53;
  color: black;
  font-size: 20px;
  border-radius: 0;
  font-weight: 500;
  padding: 5px 20px;
}
.introsec button:hover {
  background: #ab0f53;
  border: 2px solid white;
  color: white;
  font-size: 20px;
  border-radius: 0;
  font-weight: 500;
  padding: 5px 20px;
}
button.white-btn {
  color: white;
  border: 2px solid white;
  transition: background-color 0.3s ease-in-out;
  font-size: 30px;
  margin: 0 auto;
  display: table;
  border-radius: 5px;
  padding-left: 2.5%;
  padding-right: 2.5%;
}

.blog-tag-col > div {
  display: inline;
}

.blog-tag-col > button {
  background-color: #ab0f53;
}
/* button.white-btn:hover {
    background-color: white;
  }


button.blue-btn {
    background: #2674b0;
    font-size: 25px;
    padding-left: 3%;
    padding-right: 3%;
    border: none;
} */

.second-sec {
  background-color: black;
  padding: 3%;
}
section.second-sec h1 {
  color: white;
  padding-top: 5%;
}

section.second-sec p {
  color: white;
  font-size: 20px;
  padding-top: 4%;
}
.second-sec button {
  background: white;
  border: 2px solid #ab0f53;
  color: black;
  font-size: 20px;
  border-radius: 0;
  font-weight: 500;
  padding: 5px 20px;
}
.second-sec button:hover {
  background: #ab0f53;
  border: 2px solid white;
  color: white;
  font-size: 20px;
  border-radius: 0;
  font-weight: 500;
  padding: 5px 20px;
}
.flip-card {
  background-color: transparent;
  width: 100%;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background: url("../../assets/home-services-frame-graphic.webp") no-repeat
    center center;
  background-size: contain;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.flip-card-back {
  background-image: url("../../assets/home-services-frame-graphic.webp");
  color: black;
  background-repeat: no-repeat;
  background-position: center center;
  color: black;
  transform: rotateY(180deg);
  display: flex;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.flip-card-front h3 {
  color: white;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 20px;
  width: 80%;
}

button.blue-btn.home-contact-btn.btn.btn-outline-light.my-5 {
  padding: 10px 30px;
  margin: 0 auto;
  font-size: 20px;
  display: table;
  background: #e9e9e9;
  border: 2px solid #d41367;
  color: black;
  border-radius: 0;
  font-weight: 500;
}
.flip-card-back h1 {
  font-size: 20px;
  color: black;
}

.flip-card-back p {
  font-size: 16px;
  color: black !important;
}
.flip-card-back-bg {
  background: #cdcdcd;
  width: 240px !important;
  height: 240px !important;
  padding: 3%;
}

.third-sec {
  padding: 3%;
  background-color: black;
}

.third-sec h2 {
  text-align: center;
  color: white;
  font-size: 40px;
}
.third-sec p {
  text-align: center;
  color: white;
}

.fourth-sec {
  padding: 3%;
  background-image: url("../../assets/contact-cta-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  display: flex;
  /* min-height: 750px; */
}
.fourth-sec:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: linear-gradient(to bottom, black, rgba(0 0 0 / 0%));
}
.fourth-sec:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100px;
  bottom: 0px;
  left: 0px;
  background: linear-gradient(to top, black, rgba(0 0 0 / 0%));
}

.fourth-sec {
  min-height: 300px;
  padding-top: 7%;
}

.fourth-sec p {
  color: white;
  font-size: 25px;
  text-align: left;
  font-weight: 400;
}
.fourth-sec button {
  background: white;
  border: 2px solid #ab0f53;
  color: black;
  font-size: 20px;
  border-radius: 0;
  font-weight: 500;
  padding: 5px 20px;
}
.fourth-sec button:hover {
  background: #ab0f53;
  border: 2px solid white;
  color: white;
  font-size: 20px;
  border-radius: 0;
  font-weight: 500;
  padding: 5px 20px;
}

.blog-sec {
  padding: 3%;
  background: black;
}

section.blog-sec h1 {
  color: white;
}

section.blog-sec p {
  color: white;
  font-size: 20px;
}

section.blog-sec button {
  border: none;
  color: #ab0f53;
  text-decoration: underline;
  font-size: 20px;
  padding: 0;
}

/* 


.fourth-sec{
    padding: 6%;
    background-image: url('../../assets/navy-pattern-1.png');
    background-position: 50%;
    background-size: 200px;
}
.fourth-sec h1{
    color: white;
    font-size: 40px;
    text-align: center;
}
.fourth-sec h4{
    color: white;
    font-size: 25px;
    text-align: center;
}
.fourth-sec button.blue-btn{
    margin: 0 auto;
    display: table;
} */

.blog-sec button.blue-btn {
  float: right;
}
.blog-sec {
  padding: 6%;
}
.blogu-icon {
  border-radius: 50px;
  width: 50px;
}

button.blogtag-btn {
  background: rgba(171, 15, 83, 0.8);
  font-size: 18px;
  padding-left: 3%;
  padding-right: 3%;
  margin: 2%;
  color: white;
  border-radius: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

button.blogtag-btn:hover {
  background: #ad2862;
  font-size: 17px;
  padding-left: 3%;
  padding-right: 3%;
  color: white;
}
.blog-body.card-body {
  background: black;
  color: white;
  padding: 0;
  padding-top: 5%;
}

.blog-box.card {
  border: none;
  height: "300px";
  max-width: "100%";
  background: unset;
}

p.blog-date {
  color: #0000007a;
  font-size: 17px;
}

p.blog-exerpt.card-text {
  font-size: 20px;
}

h6.blog-author {
  font-size: 25px;
  margin-bottom: 0;
}
a.blog-link {
  text-decoration: none;
  color: black;
}

a.blog-link img.card-img-top {
  width: auto;
  height: 200px;
  border-radius: 0;
  border: 2px solid #9f0d4f;
}

.fifth-sec {
  padding: 3%;
  background-color: black;
}

section.fifth-sec h1 {
  color: white;
  font-size: 50px;
  padding-top: 8%;
}

section.fifth-sec p {
  font-size: 20px;
  color: white;
}
.newsletter-btn {
  margin: 0 auto !important;
  text-align: center !important;
  display: table !important;
}
.fourth-sec .form-label {
  color: white;
  margin-bottom: 0.5rem;
}
.fourth-sec input {
  background: #00000075;
}

@media (min-width: 768px) and (max-width: 1366px) {
  img.home-sec-Team2img {
    padding-top: 20%;
  }

  button.blue-btn.home-contact-btn.btn.btn-outline-light.my-5 {
    margin-top: 0 !important;
  }
}
.flip-card-back-bg a {
  color: #d41367;
  font-weight: lighter;
}

.carousel-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: bottom;
}
@media (max-width: 1200px) {
  .carousel-image {
    height: 150px; /* Height for mobile devices */
  }
}

@media (max-width: 768px) {
  .carousel-control-prev,
  .carousel-control-next {
    display: none !important;
  }
}
div.blog-inner-detail-desc a, p.blog-inner-detail-desc a {
  color: #ab0f53 !important;
}