.about-intro-img {
  text-align: right;
  float: right;
}
.meet-team-sec {
  padding-top: 3%;
  background-color: black;
}
.meet-team-sec h1 {
  text-align: center;
  color: #000;
}

.about-head-top {
  color: white;
  text-align: center;
  padding-top: 5%;
}

.about-p-top {
  text-align: center;
  color: #cdcdcd;
  font-size: 21px;
}
img.card-img-top.team-img {
  border-radius: 150px;
  width: 250px;
  border: 5px solid #40606c;
  text-align: center;
  margin: 0 auto;
  margin-bottom: -20%;
  z-index: 1;
}
.team-body.card-body {
  display: grid;
  padding-top: 20%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  border-top: 15px solid #002a3a;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 30px 0px #0000001c;
}
.card {
  box-shadow: none;
  margin-bottom: 1rem;
}
p.team-author-link {
  color: #2674b0;
  text-align: right;
}
p.team-sec-position {
  color: #0000009c;
}

.flip-cardt {
  background-color: transparent;
  width: 100%;
  height: 500px;
  perspective: 1000px;
}

.flip-card-innert {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-cardt:hover .flip-card-innert {
  transform: rotateY(180deg);
}

.flip-card-frontt > img {
  filter: grayscale(100%);
  --webkit-filter: grayscale(100%);
}

.flip-card-frontt,
.flip-card-backt {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-frontt {
  background: url("../../assets/home-services-frame-graphic.webp") no-repeat
    center center;
  background-size: contain;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.flip-card-backt {
  background-image: url("../../assets/framefintech-01.png");
  color: black;
  background-repeat: no-repeat;
  background-position: center center;
  color: black;
  transform: rotateY(180deg);
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.flip-card-backt-bg {
  background: #cdcdcd;
  width: 320px;
  overflow: auto;
  height: 320px;
  padding: 5%;
}
.flip-card-frontt h3 {
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.flip-card-backt h1, .flip-card-backt .title {
  font-size: 30px;
  color: black;
  font-weight: 500;
}
p.flipcardt-position {
  font-size: 15px;
  color: black;
  font-weight: 500;
}

p.flipcardt-desc {
  font-size: 17px;
  color: black;
}
.flip-card-backt-bg a {
  color: #c71261;
}
section.meet-team-sec button:hover {
  background: #d41367;
  border: 2px solid white;
}

section.meet-team-sec button {
  background: white;
  /* width: 300px; */
  display: table;
  margin: 0 auto;
  text-align: center;
  border: 2px solid #d41367;
  color: black;
  font-size: 20px;
  font-weight: 500;
  border-radius: 0;
}
