.blog-intro-title {
  color: white;
  text-align: center;
  font-size: 50px;
  padding-top: 5%;
}
section.blogintrosec p {
  color: #cdcdcd;
  text-align: center;
  font-size: 20px;
}
button.blog-category {
  background: none !important;
  border: none !important;
  text-align: left !important;
  color: white !important;
  padding: 0 !important;
  margin: 0 !important;
}
section.blog-page-second-sec button {
  margin-top: 5%;
}
.blog-page-second-sec {
  background-color: black;
  padding-top: 3%;
}
.blog-page-second-sec p {
  font-size: 20px;
  padding-bottom: 1%;
}
p.blog-date {
  color: #fdfdfd;
}

button.blogtag-btn.btn.btn-outline-light {
  background: rgba(171, 15, 83, 0.8);
  font-size: 14px;
  margin: 2%;
  color: white;
  border-radius: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

button.blogtag-btn.btn.btn-outline-light:hover {
  background: #aa255e;
  font-size: 14px;
  color: white;
  border-radius: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

section.blog-page-second-sec button:hover {
  border: 2px solid white;
  background: #9f0d4f;
}

section.blog-page-second-sec h3 {
  color: white;
}

section.blog-page-second-sec button {
  background: white;
  border-radius: 0;
  border: 2px solid #9f0d4f;
  color: black;
  font-weight: 500;
  font-size: 20px;
  padding: 5px 25px;
}
.blogintrosec {
  background-image: url("../../assets/home-hero-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  display: flex;
  min-height: 750px;
  margin-top: -50px;
}
.blogintrosec {
  min-height: 375px;
}

.blogintrosec:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100px;
  bottom: 0px;
  left: 0px;
  background: linear-gradient(to top, black, rgba(0 0 0 / 0%));
}
.blogintrosec:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: linear-gradient(to bottom, black, rgba(0 0 0 / 0%));
  z-index: -1;
}
.blog-page-second-sec .infinite-scroll-component {
  overflow: hidden !important;
}
.custom-dropdown-toggle {
  background-color: transparent !important;
  border: none !important;
  color: #9f0d4f !important;
  font-size: 22px !important;
}

.custom-dropdown-menu {
  background-color: black !important;
  font-size: 16px !important;
}

.custom-dropdown-item {
  color: #9f0d4f !important;
}

.custom-dropdown-item:hover {
  background-color: #9f0d4f !important;
  color: black !important;
}

.cover-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
