.events-t{
    text-align: center !important;
    float: inherit;
}
.events-sec-2{
    background-color: black;
    padding-top: 5% ;
    padding-bottom: 5%;
}
.events-sec-2 h1{
    color: white;
    text-align: center;
    margin: 0 auto;
}