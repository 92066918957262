html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
}
.secondsec-text {
  background-image: url("../../assets/home-hero-bg.webp");
  background-repeat: repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  display: flex;
  min-height: 50px;
  width: 100%;
}

@media (max-width: 386px) {
  .secondsec-text {
    background-image: url("../../assets/home-hero-bg.webp");
    background-repeat: repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    display: flex;
    min-height: 900px;
    width: 100%;
  }
}

.thirdsec-text {
  background-image: url("../../assets/home-hero-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  display: flex;
  min-height: 500px;
  width: 100%;
}

.row-icons {
  text-align: center;
  background-color: rgba(10, 5, 5, 0.7);
  position: relative;
  height: 450px;
}

.row-icons img {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 2s, transform 2s;
}

.row-icons img.visible {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 2s, transform 2s;
}

.row-icons h5 {
  color: white;
  padding-top: 25px;
}
.row-icons h6 {
  color: white;
}

.disclaimer-text {
  font-size: small;
  margin-top: 120px;
  margin-bottom: 100px;
  color: #cdcdcd;
  text-align: center;
  background-color: rgba(10, 5, 5, 0.9);
  position: relative;
  height: 50px;
  opacity: 0;
  transition: opacity 3s;
}
@media (max-width: 767px) {
  .disclaimer-text {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.disclaimer-text.visible {
  opacity: 1;
}

.buttons-row {
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 100px;
}

.buttons-row Button {
  text-align: center;
  display: table;
  margin: 0 auto;
  background: white;
  color: black;
  font-size: 1.5rem;
  font-weight: 500;
  border: 2px solid #ab0f53;
  width: 22rem;
  height: 3.5rem;
}

.buttons-row > a > button.btn.btn-primary:hover,
.buttons-row > button.btn.btn-primary:hover {
  background: #ab0f53;
  color: white;
  border: 2px solid white;
}

.buttons-row > a > button.btn.btn-primary:active,
.buttons-row > button.btn.btn-primary:active {
  background: #d41367;
  border: none;
}

.buttons-row > a > button.btn.btn-primary:focus,
.buttons-row > button.btn.btn-primary:focus {
  background: #d41367;
  border: none;
}

.buttons-row > a > button.btn.btn-primary:visited,
.buttons-row > button.btn.btn-primary:visited {
  background: #d41367;
  border: none;
}

.buttons-row > a > button.btn.btn-primary:focus-visible,
.buttons-row > button.btn.btn-primary:focus-visible {
  background: #d41367;
  border: none;
}
.overlay {
  position: absolute;
  top: 70%;
  left: 30%;
  transform: translate(-50%, -50%);
  padding: 20px;
  text-align: center;
  font-weight: 500;
}

@media (max-width: 767px) {
  .overlay {
    top: 50%;
    left: 30%;
  }
  .buttons-row Button {
    text-align: center;
    display: table;
    margin: 0 auto;
    background: white;
    color: black;
    font-size: 12px;
    font-weight: 500;
    border: 1.5px solid #ab0f53;
  }
}
@media (max-width: 767px) {
  .overlay {
    top: 50%;
    left: 30%;
  }
  .buttons-row Button {
    text-align: center;
    display: table;
    margin: 0 auto;
    background: white;
    color: black;
    font-size: 12px;
    font-weight: 500;
    border: 1.5px solid #ab0f53;
    width: 140px;
    height: fit-content;
  }
}
.intro-ai-image {
  position: relative;
  /* height: calc(100vh - 102px);*/
}

.intro-ai-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 767px) {
  .overlay {
    top: 50%;
    bottom: 5%;
  }
  .disclaimer-text {
    margin-bottom: 300px;
    margin-top: 150px;
  }
}
@media (min-width: 490px) {
  @media (max-width: 1000px) {
    .overlay {
      top: 60%;
      bottom: 5%;
    }
  }
}
@media (max-width: 370px) {
  .overlay {
    top: 43%;
  }
  .buttons-row Button {
    text-align: center;
    display: table;
    margin: 0 auto;
    background: white;
    color: black;
    font-size: 10px;
    font-weight: 500;
    border: 1px solid #ab0f53;
    width: 120px;
    height: 25px;
  }
}
@media (max-width: 350px) {
  .overlay {
    top: 45%;
  }
  .buttons-row Button {
    text-align: center;
    display: table;
    margin: 0 auto;
    background: white;
    color: black;
    font-size: 10px;
    font-weight: 500;
    border: 1px solid #ab0f53;
    width: 120px;
    height: 25px;
  }
}
@media (max-width: 300px) {
  .overlay {
    top: 35%;
  }
}
