.nav-link {
  font-size: 17px !important;
  font-weight: 500;
}

.nav-link-url {
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  font-weight: 500;
}
/* Add this CSS to your Header.css or project's styles */
.navbar-expand-lg .navbar-nav .nav-item:hover .nav-link-url + .dropdown-menu {
  display: block;
}

.navbar-expand-lg .navbar-nav .nav-item .nav-link-url + .dropdown-menu.show {
  display: block;
}

a.dropdown-item {
  color: white !important;
}
.navbar-nav .dropdown-menu {
  position: static;
  background: black;
  border-radius: 0;
  border: 2px solid #ab0f53;
}

a.nav-link-url:hover {
  background: black !important;
  color: white;
  text-decoration: underline;
}

a.dropdown-item:hover {
  background: black;
}
.dropdown-toggle::after {
  border-top: 0.3em solid white !important;
}
nav.navbar {
  background: black;
  box-shadow: 0px 0px 50px 0px #00000014;
  z-index: 999;
}

.navbar-toggler-icon {
  background-color: #ab0f53;
}

/* Optionally, you can add this CSS to change the color when toggled */
.navbar-toggler-icon.active {
  background-color: #ab0f53;
}

.new-img {
  width: 14px;
  height: 14px;
  position: relative;
  bottom: 5px;
  padding-left: 4px;
}

